import React, { useState, useEffect } from "react";
import ListItem from "../components/ListItem";
import { ReactComponent as NoteIcon } from "../assets/align-text-left.svg";
import AddButton from "../components/AddButton";

const NotesListPage = () => {
  let [notes, setNotes] = useState([]); //update the states

  useEffect(() => {
    //call that inside of useEffect
    getNotes();
  }, []);

  let getNotes = async () => {
    console.log(process.env.REACT_APP_BACKEND_URL);
    //make a call
    let response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/notes/`
    );
    let data = await response.json();
    setNotes(data);
  };

  return (
    //render the data out
    <div className="notes">
      <div className="notes-header">
        <h2 className="notes-title">Notes</h2>
        <p className="notes-count">{notes.length}</p>
      </div>
      <div className="notes-list">
        {notes.map((note, index) => (
          <ListItem key={index} note={note} />
        ))}
      </div>

      <AddButton />
    </div>
  );
};

export default NotesListPage;
