import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "../assets/arrow-left.svg";
import useAutosave from "../components/useAutosave.hook";

const NotePage = ({ history }) => {
  let navigate = useNavigate();
  let noteId = useParams().id;
  console.log("noteId:" + noteId);

  let [note, setNote] = useState(null);

  useEffect(() => {
    getNote();
  }, [noteId]);

  useAutosave(() => {
    updateNote();
  }, 10 * 1000);

  let getNote = async () => {
    if (noteId == "new") return;

    let response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/notes/${noteId}/`
    );
    let data = await response.json();
    setNote(data);
  };

  let createNote = async () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/notes/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(note),
    });
  };

  let updateNote = async () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/notes/${noteId}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(note),
    });
  };

  let deleteNote = async () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/notes/${noteId}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    navigate("/");
  };

  let handleSubmit = async () => {
    // await updateNote();
    console.log(note.body);
    if (noteId !== "new" && note.body === "") {
      await deleteNote();
    } else if (noteId !== "new") {
      await updateNote();
    } else if (noteId === "new" && note !== null) {
      await createNote();
    }
    navigate("/");
  };

  return (
    <div className="note">
      <div className="note-header">
        <h3>
          <ArrowLeft onClick={handleSubmit} />
        </h3>
        {noteId !== "new" ? (
          <button onClick={deleteNote}>Delete</button>
        ) : (
          <button onClick={deleteNote}>Cancel</button>
        )}
      </div>
      <textarea
        onChange={async (e) => {
          setNote({ ...note, body: e.target.value });
        }}
        defaultValue={note?.body}
      ></textarea>
    </div>
  );
};

export default NotePage;
