import React from "react";

const Header = () => {
  return (
    <div className="app-header">
      <h1>Note List</h1>
    </div>
  );
};

export default Header;
